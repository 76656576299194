<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8 w-full">
        <h2 class="text-3xl">Individual Observation Reports</h2>
        <StudentList v-if="!route.query.student" :cohortStudents="cohortStudents" />
        <StudentObserv v-if="route.query.student" :cohortStudents="cohortStudents" :student="route.query.student" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, watch } from 'vue';
import StudentList from '../components/observations/summary/individual/StudentList.vue';
import { useRoute } from 'vue-router';
import StudentObserv from '../components/observations/summary/individual/StudentObserv.vue';

const userDetails = inject('userDetails')
const route = useRoute()
console.log({route: route.query.student})

const cohortStudents = computed(() => {
  const cohortArray = []
  if (!userDetails.value?.school_info)
    return cohortArray
  userDetails.value.school_info.cohorts.forEach((cohort) => {
    const cohortObj = {
      name: cohort.name,
      cohort_name: cohort.cohort_name,
      is_sub_cohort: cohort.is_sub_cohort,
      students: []
    }

    if (cohort.cohort_name !== 'Batatas')
    cohortArray.push(cohortObj)
  })

  userDetails.value.school_info.students.forEach((student) => {
    const cohortIndex = cohortArray.findIndex(cohort => cohort.name === student.cohort)
    if (cohortIndex < 0) 
      return
    cohortArray[cohortIndex].students.push({
      name: student.name,
      student_name: student.student_name,
    })
  })

  // Sort students array for each cohort based on student_name
  cohortArray.forEach(cohort => {
    cohort.students.sort((a, b) => 
      a.student_name.localeCompare(b.student_name)
    )
  })

  return cohortArray
})
</script>