<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 1 && `hidden`)" >
    <div class="space-y-3">
      <label class="font-medium text-sm md:text-base">Your name:</label>
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none hidden" 
        id="activity-observ-form-edu-id" name="educator_id" v-if="props.userData"
        :value="props.userData.has_educator_doc ? props.userData.educator_id : ''" readonly>
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
        font-normal opacity-70" 
        id="activity-observ-form-edu-name" name="educator_name" v-if="props.userData" 
        :readonly="true" autocomplete="off"
        :value="props.userData.has_educator_doc ? props.userData.educator_name : ''">
    </div>
    <div class="flex justify-end">
      <div class="flex items-center gap-2">
        <label for="hs-activity-obs-date-switch" class="text-sm text-gray-500 ms-3 dark:text-neutral-400">
          Select date manually
        </label>
        <input type="checkbox" id="hs-activity-obs-date-switch" class="relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-[#FDD835] disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-[#FDD835] checked:border-[#FDD835] focus:checked:border-[#FDD835] dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600
        before:inline-block before:size-4 before:bg-white checked:before:bg-[#fff6e9] before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
        :checked="isManualDate" @change="updateIsManualDateToggle">
      </div>
    </div>
    <div v-if="isManualDate" class="flex flex-col gap-4">
      <div class="space-y-3">
        <label for="activity-observ-form-date" class="font-medium text-sm md:text-base">
          Date of observation:
        </label>
        <input type="date" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
          font-normal"
          id="activity-observ-form-date" name="date" v-model="dateValue" :max="formattedDate"
          @change="emitDate" @click="openDatePicker">
      </div>
      <div class="space-y-3">
        <p class="font-medium text-sm md:text-base">
          Time of activity:
        </p>
        <div class="flex gap-x-6 gap-y-2 text-xs md:text-sm font-medium flex-wrap">
          <div class="flex gap-0">
            <label class="text-gray-700 ms-2 flex gap-4 items-center">
              <input type="radio" name="activity_time" class="shrink-0 mt-0.5 border-gray-200 rounded-full
                text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                id="hs-activity-session-group-1" value="Morning session" v-model="activityTime" >
              Morning Session
            </label>
          </div>
          <div class="flex">
            <label class="text-gray-700 ms-2 flex gap-4 items-center">
              <input type="radio" name="activity_time" class="shrink-0 mt-0.5 border-gray-200 rounded-full
                text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                id="hs-activity-session-group-2" value="Afternoon session" v-model="activityTime" >
              Afternoon Session
            </label>
          </div>
          <div class="flex">
            <label class="text-gray-700 ms-2 flex gap-4 items-center">
              <input type="radio" name="activity_time" class="shrink-0 mt-0.5 border-gray-200 rounded-full
                text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                id="hs-activity-session-group-3" value="Invitation to Play" v-model="activityTime" >
                Invitation to Play
            </label>
          </div>
        </div>
      </div>
      <div class="space-y-3">
        <p class="font-medium text-sm md:text-base">
          Activity conducted for:
        </p>
        <!-- below was a flex row of 2 flex columns originally - convert to grid if needed -->
        <div class="flex gap-x-10 md:gap-x-14 font-medium text-xs md:text-sm">
          <div class="grid grid-cols-2 sm:grid-cols-3 gap-y-2 gap-x-6" v-if="props.userData">
            <div v-for="(cohort, index) in filteredCohorts" class="flex">
              <label class="text-gray-700 ms-3 flex gap-4">
                <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600
                  focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  :id="`hs-cohort-checkbox-group-${index}`"
                  :value="cohort.name" name="cohorts" v-model="checkedCohorts">
                <!-- Bombils (2/3)s -->
                {{ cohort.cohort_name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col gap-4">
      <label class="font-medium text-sm md:text-base">Activity:</label>
      <div class="flex gap-2">
        <div v-for="date in dateRange" :class="cn(`flex flex-col justify-center items-center gap-1 rounded-full 
          size-16 bg-[#fff6e9] cursor-pointer`,
          selectedDate.toDateString() === date.toDateString() && 'bg-[#ff9900]',
          [0,6].includes(date.getDay()) && 'opacity-50',
          !sortedEventsObj[date.toDateString()]?.length && 'opacity-50',
        )" @click="() => changeEventSelectionDate(date)">
          <p class="">{{ getDayInitial(date) }}</p>
          <p class="text-xs">{{ formatDate(date) }}</p>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div v-for="event in sortedEventsObj[selectedDate.toDateString()]"
          v-if="sortedEventsObj[selectedDate.toDateString()]?.length"
          :class="cn('border rounded-lg shadow-sm p-2 cursor-pointer text-xs sm:text-sm flex flex-col gap-2 relative',
          selectedEvent === event.name && 'border-[#FDD835] border-2',
          props.observation?.event?.name === event.name && 'border-[#FDD835] border-2 border-dashed')"
          @click="() => selectEvent(event)">
          <!-- {{ event }} -->
          <div v-if="selectedEvent === event.name" class="absolute top-2 right-2 border-[#FDD835] border-1 border rounded p-1">selected</div>
          <div class="flex gap-2">
            <p class="font-medium">Activity Title:</p>
            <p v-if="event.linked_activity_name">{{ event.linked_activity_name }}</p>
            <p v-else-if="event.activity_name">{{ event.activity_name }}</p>
            <p v-else>Unnamed Activity</p>
          </div>
          <!-- <div class="flex gap-2">
            <p class="font-medium">Date:</p>
            <p>{{ formatDate(new Date(event.start)) }}</p>
          </div> -->
          <div class="flex gap-2">
            <p class="font-medium">Session:</p>
            <p>{{ event.session }}</p>
          </div>
          <div class="flex gap-2">
            <p class="font-medium">Cohorts:</p>
            <div class="flex flex-wrap gap-2">
              <div v-for="cohort in event.cohort"
                class="bg-[#FDD835] px-2 rounded">
                {{ findValueByName(userDetails?.school_info.cohorts, cohort.link_to_cohort, 'cohort_name') }}
              </div>
            </div>
          </div>
          <div class="flex gap-2">
            <p class="font-medium">Led by:</p>
            <div class="flex flex-wrap gap-2">
              <div v-for="educator, index in event.led_by" >
                <span v-if="index !== 0">, </span>
                {{ findValueByName(userDetails?.school_info.educators, educator.link_to_educator, 'educator_name') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { computed, inject, ref, watch } from 'vue';
import { openDatePicker } from '@/lib/utils';
import { cohortsIgnoreList } from '@/lib/filterData';
import { truncateWithEllipsis, findValueByName, getFormattedDate } from '@/lib/utils'

const props = defineProps({
  pageNum: {
    type: Number,
    default: 1
  }, userData: Object
  , autoFillData: Object
  , isManualDate: Boolean,
})
const emit = defineEmits(['update:cohort', 'date:selected', 'select:event', 'update:isManualDate'])

const userDetails = inject('userDetails')

const getDayInitial = (date) => {
  const dayName = date.toLocaleDateString('en-US', { weekday: 'long' })
  return dayName[0].toUpperCase()
}

const formatDate = (date) => {
  // Format as "dd MMM" (e.g., "19 Feb")
  const day = date.getDate().toString().padStart(2, '0')
  const month = date.toLocaleDateString('en-US', { month: 'short' })
  return `${day} ${month}`
}

const generateDateRange = () => {
  const today = new Date()
  const lastWeek = new Date(today)
  lastWeek.setDate(lastWeek.getDate() - 7)
  
  const dates = []
  const currentDate = new Date(lastWeek)
  
  while (currentDate <= today) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  
  return dates;
}

const dateRange = ref(generateDateRange())
const selectedDate = ref(new Date())

const sortedEventsObj = computed(() => {
  if (!userDetails.value?.educator_events?.length) return {}
  const eventsObj = {}
  userDetails.value.educator_events.forEach((event) => {
    const eventDateString = new Date(event.start).toDateString();

    if (eventsObj[eventDateString]) {
      eventsObj[eventDateString].push(event);
    } else {
      eventsObj[eventDateString] = [event];
    }
  })

  return eventsObj
})

const selectedEvent = ref(sortedEventsObj.value[selectedDate.value.toDateString()]?.length ? sortedEventsObj.value[selectedDate.value.toDateString()][0].name : '')
const selectEvent = (event) => {
  emit('select:event', event)
  selectedEvent.value = event.name;
}

if (selectedEvent.value && sortedEventsObj.value[selectedDate.value.toDateString()]?.length) {
  emit('select:event', sortedEventsObj.value[selectedDate.value.toDateString()][0]);
}

const updateIsManualDateToggle = (event) => {
  emit('update:isManualDate', event.target.checked);
}

const changeEventSelectionDate = (date) => {
  // saturday and sunday - holidays
  if ([0,6].includes(date.getDay)) return;
  if (!sortedEventsObj.value[date.toDateString()]?.length) return;
  selectedDate.value = date;
  if (sortedEventsObj.value[date.toDateString()]?.length) {
    selectedEvent.value = sortedEventsObj.value[date.toDateString()][0].name;
    emit('select:event', sortedEventsObj.value[date.toDateString()][0])
  } else {
    selectEvent.value = '';
  }
}

const cohortList = ['Bombils', 'Batatas-1', 'Batatas-2', 'Bimblis', 'BigKids']

const filteredCohorts = computed(() => {
  if (props.userData) {
    const filteredSortedArray = props.userData.school_info.cohorts.filter((cohort) => {
      return !cohortsIgnoreList.includes(cohort.cohort_name)
    // }).sort((cohortA, cohortB) => {
    //   return cohortList.indexOf(cohortA.cohort_name) - cohortList.indexOf(cohortB.cohort_name)
    })
    return filteredSortedArray
  }
  return []
})

const checkedCohorts = ref([])
const activityTime = ref('')

watch(checkedCohorts, (newCohorts) =>{
  // console.log(newCohorts)
  emit('update:cohort', newCohorts)
})

// const isNameFilled = computed(() => {
//   if (props.userData.is_educator) {
//     return true
//   }
//   return false
// })

// const areAllInputsFilled = () => {
//   return isNameFilled.value && !!checkedCohorts.value && !!activityTime.value
// }
// defineExpose({areAllInputsFilled})

const today = new Date();
// Format today's date in YYYY-MM-DD format (required for input type date)
const formattedDate = today.toISOString().split('T')[0];

const dateValue = ref(formattedDate)

const emitDate = () => {
  emit('date:selected', dateValue.value)
}

if (props.autoFillData?.cohorts) {
  checkedCohorts.value = props.autoFillData.cohorts;
}
if (props.autoFillData?.date) {
  // console.log("Passed date: ", props.autoFillData.date)
  // console.log("NEw date: ", new Date(props.autoFillData.date).toISOString().split('T')[0])
  // dateValue.value = new Date(props.autoFillData.date).toISOString().split('T')[0];
  console.log('Original date string:', props.autoFillData.date);
  console.log('Direct date split:', props.autoFillData.date.split(' ')[0]);
  console.log('Date as ISO:', new Date(props.autoFillData.date).toISOString());
  console.log('Date with local adjustment:', new Date(props.autoFillData.date).toLocaleString());
  // dateValue.value = props.autoFillData.date.split(' ')[0];
  const [year, month, day] = props.autoFillData.date.split(' ')[0].split('-');
  dateValue.value = `${year}-${month}-${day}`; // Ensures no time zone interference
}
if (props.autoFillData?.session) {
  activityTime.value = props.autoFillData.session;
}

watch(() => props.autoFillData, (newAutoFillData) => {
  if (newAutoFillData?.cohorts) {
    checkedCohorts.value = newAutoFillData.cohorts;
  } else {
    checkedCohorts.value = []
  }
  if (newAutoFillData?.date) {
    // dateValue.value = new Date(props.autoFillData.date).toISOString().split('T')[0];
    console.log('Original date string:', props.autoFillData.date);
    console.log('Direct date split:', props.autoFillData.date.split(' ')[0]);
    console.log('Date as ISO:', new Date(props.autoFillData.date).toISOString());
    console.log('Date with local adjustment:', new Date(props.autoFillData.date).toLocaleString());
    dateValue.value = props.autoFillData.date.split(' ')[0];
    const [year, month, day] = props.autoFillData.date.split(' ')[0].split('-');
    dateValue.value = `${year}-${month}-${day}`; // Ensures no time zone interference
  } else {
    dateValue.value = formattedDate;
  }
  if (newAutoFillData?.session) {
    activityTime.value = newAutoFillData.session;
  } else {
    activityTime.value = ''
  }
})
</script>