<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 
        dark:border-gray-700 dark:shadow-slate-700/[.7] h-full">
        <div class="space-y-2">
          <div class="flex justify-between items-center pt-4 px-4 dark:border-gray-700">
            <h3 class="font-semibold text-[#6B7280] text-lg md:text-xl">
              Weekly Observation
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
          <DateDisplay :date="selectedDate" />
        </div>
        <form @submit.prevent="handleSubmit" name="unstruct-observ-form" class="flex-auto flex flex-col font-normal"
          ref="formRef">
          <UnstructFormPage1 v-if="studentsList.data && userDetails" :pageNum="pageNum" :userData="userDetails" 
            :studentsList="studentsList.data" @date:selected="updateDate" />
          <UnstructFormPage2 :pageNum="pageNum" :userData="userDetails" @audioRecorded="saveAudioBlob"
            @audioDeleted="removeAudioBlob" @transcriptGenerated="markTranscriptGenerated" />
          <UnstructFormImages :pageNum="pageNum" :componentPageNum="3" @uploadComplete="handleImagesUploadComplete"  
            @formSubmittableStatusChanged="updateFormSubmittableStatus" />
          <div :class="cn(`text-sm font-semibold text-red-500 p-3 text-center`, 
            hideInputWarning && 'hidden')">
            Please answer all the required questions!
          </div>
          <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-between">
            <div class="flex gap-2 items-center">
              <div class="px-8 md:px-4 flex gap-1 items-center">
                <div v-for="n in 3" :class="cn(`h-2 w-3 bg-[#F3F4F6]`, n <= pageNum && 'bg-[#FFD700]')">
                  <!-- <div :class="cn(`h-full w-full`, n <= pageNum && 'bg-[#FFD700]')"></div> -->
                </div>
              </div>
              <div class="text-sm font-semibold text-gray-600">
                {{ pageNum }}/3
              </div>
            </div>
            <div class="flex justify-center md:justify-end items-center gap-x-4 py-3 px-4 dark:border-gray-700">
              <button type="button" class="py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700
                dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1
                dark:focus:ring-gray-600" @click="goToPrevPage" :disabled="pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-md border border-transparent text-white text-white
                disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                disabled:bg-[#4B5563] disabled:text-[#D1D5DB] bg-black hover:bg-[#1F2937]
                dark:focus:ring-gray-600`, pageNum === 3 && 'hidden')" @click="goToNextPage" :disabled="pageNum === 3">
                Next
              </button>
              <button type="submit" :class="cn(`py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-md border border-transparent text-white text-white
                disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                disabled:bg-[#4B5563] disabled:text-[#D1D5DB] bg-black hover:bg-[#1F2937]
                dark:focus:ring-gray-600`, pageNum !== 3 && 'hidden')" :disabled="(pageNum !== 3) || isSubmitting || !isSubmitEnabled">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue';
import { cn } from '@/components/ui/utils.js'
import { createResource, createListResource } from 'frappe-ui'
import { HSOverlay } from 'preline/preline';
import DateDisplay from './DateDisplay.vue';
import UnstructFormPage1 from './UnstructForm/UnstructFormPage1.vue'
import UnstructFormPage2 from './UnstructForm/UnstructFormPage2.vue'
import UnstructFormImages from './UnstructForm/UnstructFormImages.vue';

const props = defineProps(['modalName', 'userData', 'reloadObservations',
  'confirmModalName'
])

const userDetails = inject('userDetails')

const pageNum = ref(1)
const selectedDate = ref(null)

const formRef = ref(null)
const hideInputWarning = ref(true)

let audioBlob = null
const isTranscriptGen = ref(false)

const setHideWarningTime = (time) => {
  setTimeout(() => {
    if (hideInputWarning.value === false)
      hideInputWarning.value = true
  }, time)
}

onMounted(() => {
  // console.log("preline mounted and initiated in App.vue");
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const studentsList = createListResource({
  doctype: 'Student',
  fields: ['name', 'student_name', 'cohort'],
  orderBy: 'student_name asc',
  filters: {
    is_enrolled: 1,
  },
  start: 0,
  pageLength: 30,
  auto: true
})

const uploadedImageUrls = ref([]);
const isSubmitEnabled = ref(true)


const updateFormSubmittableStatus = (status) => {
  console.log("R: this is form submittable status in DailyObservationModal",status)

  // Status will be true when either no image is added in the uploader or all the selected/added images have been upoaded 
  // To handle status of Submit button, initialise submit button with a default 'true' value for case when user does not want to upload any picture
  isSubmitEnabled.value = status;
};

const handleImagesUploadComplete = (response) => {
  if (response.body) {
    uploadedImageUrls.value.push(response.body.location);
    console.log("S3: image URL received in DailyObservationModal:", uploadedImageUrls.value);
    
  }
};

const goToPrevPage = () => {
  if (pageNum.value === 1) return
  pageNum.value = pageNum.value - 1
}

const goToNextPage = () => {
  if (pageNum.value === 3) return
  const formData = new FormData(formRef.value)
  if (pageNum.value === 1) { 
    const eduId = formData.get('educator_id')
    const date = formData.get('date')
    const about = formData.get('about')
    if (!(eduId && date && about)) {
      hideInputWarning.value = false
      return
    } else {
      hideInputWarning.value = true
    }
  } else if (pageNum.value === 2) {
    if (!formData.get('feedback_text') && !audioBlob) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      isSubmitting.value = false
      return
    }
  }
  hideInputWarning.value = true
  pageNum.value = pageNum.value + 1
}

const updateDate = (date) => {
  console.log(date)
  selectedDate.value = date
}

const feedbackResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.unstructured_feedback.api.create_feedback'
})

const isSubmitting = ref(false)

const handleSubmit = async (e) => {
  if (!isSubmitEnabled.value) return;
  isSubmitting.value = true
  const formData = new FormData(e.target)
  
  const formDataObj = {};
  formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  if (formDataObj.about === 'Students' && !Array.isArray(formDataObj.students)) {
    formDataObj.students = [formDataObj.students]
  }

  // Adding the uplaoded image url in form data
  if (uploadedImageUrls.value.length > 0) {
      formDataObj.uploaded_image_urls = [...uploadedImageUrls.value];
  } else {
      console.log('uploaded images array is empty(Weekly Observation)!')
      formDataObj.uploaded_image_urls = []; // Ensure it's an empty array if no URLs exist
  }

  console.log(formDataObj)
  if (!isTranscriptGen.value && audioBlob) {
    const educatorID = props.userData.educator_id
    const fileName = `${educatorID}_${Date.now()}.webm`
    const audio_file = new File([audioBlob], fileName, { type: "audio/wav" })

    const audioFormData = new FormData();
    audioFormData.append('audio_file', audio_file)
    audioFormData.append('feedback_data', JSON.stringify(formDataObj))

    try {
      const res = await fetch('/api/method/curriculum_planner.curriculum_planner.doctype.unstructured_feedback.api.create_feedback_async', {
        method: 'POST',
        headers: {
          'X-Frappe-Csrf-Token': window.csrf_token
        },
        body: audioFormData
      })
      const res_data = await res.json()
      if (res_data.message.status === 'success') {
        // feedbackText.value = res_data.message.transcript
        HSOverlay.close(`#hs-${props.modalName}`)
        pageNum.value = 1
        isSubmitting.value = false
        HSOverlay.open('#hs-form-confirm-modal-3')
      } else if (res_data.message.status == 'fail') {
        alert("Transcript generation failed!")
        isSubmitting.value = false
      }
    } catch (error) {
      statusText.value = "Transcript generation failed! Please try again."
      console.log('Transcription generation failed')
      console.error(error)
    }
  } else {
    feedbackResource.update({
      params: {
        // feedback_data: JSON.stringify(formDataObj),
        data_dict: formDataObj,
    }})
    try {
      await feedbackResource.submit()
      console.log(feedbackResource)
      if (feedbackResource.data.status === 'success') {
        HSOverlay.close(`#hs-${props.modalName}`)
        pageNum.value = 1
        isSubmitting.value = false
        HSOverlay.open(`#hs-form-confirm-modal-${props.confirmModalName}`)
        if (props.reloadObservations) props.reloadObservations();
      } else if (feedbackResource.data.status === 'fail') {
        alert("Form Submission Failed. Make sure you have filled everything correctly.")
        isSubmitting.value = false
      }
    } catch (error) {
      console.error(error)
    }
  }
}

const saveAudioBlob = (blob) => {
  console.log("Saving audio files")
  audioBlob = blob
  console.log(blob)
}

const removeAudioBlob = () => {
  audioBlob = null
  console.log("Removieng audio files")
  console.log(audioBlob)
}

const markTranscriptGenerated = () => {
  isTranscriptGen.value = true
}
</script>