<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto print:overflow-visible print:h-max" ref="pageRef">
    <div class="flex justify-between items-center gap-2">
      <div class="flex flex-col gap-2 w-full">
        <h2 class="text-3xl">
          {{ studentObj.official_name || studentObj.student_name }}'s Observation Report
          <span v-if="userDetails?.is_educator || userDetails?.is_system_manager"
            class="text-sm font-medium text-gray-600">
            {{ isPublished ? "(Published)" : "(Not Published)" }}
          </span>
        </h2>
        <p v-if="loadReportResource.data?.report" class="text-gray-600 text-sm font-semibold">
          {{ formatDateWithSuffix(loadReportResource.data.report.creation) }}, {{ new Date(loadReportResource.data.report.creation).getFullYear() }}
          <!-- change 2024 later -->
        </p>
        <!-- <StudentList v-if="!route.query.student" :cohortStudents="cohortStudents" />
        <StudentObserv v-if="route.query.student" :cohortStudents="cohortStudents" :student="route.query.student" /> -->
      </div>
      <button v-if="!isEditing && (userDetails?.is_educator || userDetails?.is_system_manager)" 
        type="button" class="py-2 px-6 inline-flex items-center gap-x-2 text-sm font-semibold 
        rounded border border-transparent bg-gray-800 text-white hover:bg-gray-900 
        disabled:opacity-50 disabled:pointer-events-none dark:bg-white dark:text-neutral-800 flex-nowrap
        print:hidden text-nowrap" @click="isEditing = true">
        Edit Report
      </button>
      <div v-else-if="userDetails?.is_educator || userDetails?.is_system_manager" class="flex gap-2">
        <button type="button" class="py-2 px-6 inline-flex items-center gap-x-2 text-sm font-semibold 
          rounded border border-gray-200 bg-white text-gray-800 hover:bg-gray-200 
          disabled:opacity-50 disabled:pointer-events-none dark:bg-white dark:text-neutral-800 flex-nowrap
          text-nowrap" @click="isEditing = false">
          Cancel
        </button>
        <button type="button" class="py-2 px-6 inline-flex items-center gap-x-2 text-sm font-semibold 
          rounded border border-transparent bg-gray-800 text-white hover:bg-gray-900 
          disabled:opacity-50 disabled:pointer-events-none dark:bg-white dark:text-neutral-800 flex-nowrap
          text-nowrap" @click="saveEditedReport">
          Save
        </button>
      </div>

      <div class="hs-dropdown relative inline-flex print:hidden" 
        v-if="userDetails?.is_educator || userDetails?.is_system_manager">
        <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex 
          items-center gap-[0.1rem] text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 
          shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 
          disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white 
          dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 flex-col" 
          aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
          <div class="rounded-full bg-black h-1 w-1"></div>
          <div class="rounded-full bg-black h-1 w-1"></div>
          <div class="rounded-full bg-black h-1 w-1"></div>
        </button>

        <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 
          opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-1 space-y-0.5 mt-2 dark:bg-neutral-800 
          dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute 
          after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 
          before:w-full flex flex-col z-20" 
          role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-default">
          <button class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border 
            border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
            disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 
            dark:focus:bg-neutral-700" @click="printPage">
            Download Report
          </button>
          <button v-if="showSideBySide === false"
            class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border 
            border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
            disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 
            dark:focus:bg-neutral-700" @click="showSideBySide = true"
            :disabled="!loadReportResource.data?.report?.edited_versions.length ||
            loadReportResource.data?.report?.is_original_preferred">
            Show Original
          </button>
          <button v-if="showSideBySide === true"
            class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border 
            border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
            disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 
            dark:focus:bg-neutral-700" @click="showSideBySide = false"
            :disabled="!loadReportResource.data?.report?.edited_versions.length">
            Show Latest
          </button>
          <button class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border 
            border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
            disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 
            dark:focus:bg-neutral-700" @click="resetToOriginal"
            :disabled="!loadReportResource.data?.report?.edited_versions.length || 
            loadReportResource.data?.report?.is_original_preferred">
            Reset Edits
          </button>
          <button class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border 
            border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
            disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 
            dark:focus:bg-neutral-700" @click="publishReport" v-if="!isPublished"
            >
            <!-- :disabled=""> -->
            Publish Report
          </button>
          <button class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border 
            border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
            disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 
            dark:focus:bg-neutral-700" @click="unPublishReport" v-if="isPublished"
            >
            <!-- :disabled=""> -->
            Unpublish Report
          </button>
          <button class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border 
            border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 
            disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 
            dark:focus:bg-neutral-700" @click="regenReport"
            :disabled="isSavingComments || isEditing || isEditingComments"
            >
            <!-- :disabled=""> -->
            Regenerate With Comments
          </button>
        </div>
      </div>
    </div>

    <div>
        <div class="flex gap-2">
          <div class="flex gap-4 items-center">
            <p>Report type:</p>
            <ReportTypeDropdown :dataArray="reportTypes" :selectedData="selectedReportType"
              :changeSelectedData="changeReportType" />
          </div>
          <div class="flex gap-4 items-center">
            <p>Interval:</p>
            <ReportTypeDropdown :dataArray="intervals" :selectedData="selectedInterval"
            :changeSelectedData="changeInterval" />
          </div>
        </div>
    </div>

    <div v-if="loadReportResource.data?.status === 'success' && loadReportResource.data?.report !== null" class="space-y-8 mb-16">
      <div class="flex items-center space-x-2 text-gray-700" v-if="isPrinting || isSavingReport || isSavingComments || isRegenerating">
        <div class="w-5 h-5 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
        <span class="text-lg font-medium">
          <span class="" v-if="isPrinting">
            Generating PDF file. Please wait...
          </span>
          <span v-if="isSavingReport" class="">
            Saving edited report. Please wait...
          </span>
          <span v-if="isSavingComments" class="">
            Saving Comment. Please wait...
          </span>
          <span v-if="isRegenerating" class="">
            Regenerating Report. Please wait...
          </span>
        </span>
      </div>
      <div class="flex flex-col gap-4" v-if="userDetails?.is_educator || userDetails?.is_system_manager">
        <div class="flex justify-end gap-2">
          <button v-if="!isEditingComments && (userDetails?.is_educator || userDetails?.is_system_manager)"
            type="button" class="py-2 px-6 inline-flex items-center gap-x-2 text-sm font-semibold
            rounded border border-transparent bg-gray-800 text-white hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none dark:bg-white dark:text-neutral-800 flex-nowrap
            print:hidden text-nowrap" @click="isEditingComments = true">
            Edit Comments
          </button>
          <div v-else-if="userDetails?.is_educator || userDetails?.is_system_manager" class="flex gap-2">
            <button type="button" class="py-2 px-6 inline-flex items-center gap-x-2 text-sm font-semibold
              rounded border border-gray-200 bg-white text-gray-800 hover:bg-gray-200
              disabled:opacity-50 disabled:pointer-events-none dark:bg-white dark:text-neutral-800 flex-nowrap
              text-nowrap" @click="isEditingComments = false">
              Cancel
            </button>
            <button type="button" class="py-2 px-6 inline-flex items-center gap-x-2 text-sm font-semibold
              rounded border border-transparent bg-gray-800 text-white hover:bg-gray-900
              disabled:opacity-50 disabled:pointer-events-none dark:bg-white dark:text-neutral-800 flex-nowrap
              text-nowrap" @click="saveEditedComments">
              Save
            </button>
          </div>
        </div>
        <div class="relative">
          <textarea id="hs-textarea-autoheight-to-destroy" class="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
          focus:pt-6
          focus:pb-2
          [&:not(:placeholder-shown)]:pt-6
          [&:not(:placeholder-shown)]:pb-2
          autofill:pt-6
          autofill:pb-2" placeholder="This is a textarea placeholder" data-hs-textarea-auto-height="" rows="10"
          :readonly="!isEditingComments" v-model="comments" ref="notesInputRef" @input="textareaAutoHeight"></textarea>
          <label for="hs-textarea-autoheight-to-destroy" class="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
            peer-focus:text-xs
            peer-focus:-translate-y-1.5
            peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
            peer-[:not(:placeholder-shown)]:text-xs
            peer-[:not(:placeholder-shown)]:-translate-y-1.5
            peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">Comments</label>
        </div>
        <!-- <p>Comments:</p> -->
      </div>
      <div v-if="loadReportResource.data?.report && userDetails?.is_system_manager" class="flex flex-col gap-2 whitespace-pre-wrap border border-gray-200 p-4 rounded-xl">
        <p class="font-semibold">Report Changes Log:</p>
        <div>{{ loadReportResource.data?.report.log }}</div>
      </div>
      <p class="font-semibold">Report: </p>
      <div v-if="!isEditing" class="flex gap-4">
        <div v-html="mdToHtml(editedReport) ||
            mdToHtml((loadReportResource.data?.report?.is_original_preferred || !loadReportResource.data?.report?.edited_versions?.length)
              ? loadReportResource.data?.report.report
              : loadReportResource.data?.report.edited_versions[loadReportResource.data?.report?.edited_versions?.length - 1].report)"
          class="whitespace-pre text-wrap richDisplay border border-gray-200 p-4 rounded-xl leading-5
          text-sm text-gray-800 shadow-sm hover:shadow flex-auto basis-1/2 custom-html-class">
        </div>
        <div v-if="showSideBySide" v-html="mdToHtml(loadReportResource.data?.report?.report)"
          class="whitespace-pre text-wrap richDisplay border border-gray-200 p-4 rounded-xl leading-5
          text-sm text-gray-800 shadow-sm hover:shadow flex-auto basis-1/2 custom-html-class">
        </div>
      </div>
      <div v-else>
        <QuillEditorMinimal
          :editorContent="mdToHtml(editedReport) ||
          mdToHtml((loadReportResource.data?.report?.is_original_preferred || !loadReportResource.data?.report?.edited_versions.length)
            ? loadReportResource.data?.report.report
            : loadReportResource.data?.report.edited_versions[loadReportResource.data?.report?.edited_versions?.length - 1].report)"
          @change:content="modifyReport" />
      </div>
      <div class="space-y-4 lg:space-y-8">
        <h3 class="font-semibold">Competencies:</h3>
        <div class="h-[50vh] min-h-[50vh] w-full">
          <BarChartColour :scoreAmountProductArray="countScoreComp" :scoreAmountProductsNames="barChartDimNames"
            :mapText="barChartScoreText" :maxScore="roundUpToNearestFive(maxCount)"
            @urlGenerated="(url) => setChartUrl(1, url)" />
        </div>
        <div class="flex justify-end items-center p-8">
          Graph Observation threshold:
          <div class="relative flex items-center">
            <input type="number" class="peer py-0 pe-0 ps-2 block bg-transparent border-t-transparent
              border-b-2 border-x-transparent border-b-gray-200 text-sm focus:border-t-transparent
              focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50
              disabled:pointer-events-none dark:border-b-neutral-700 dark:text-neutral-400 w-12
              dark:placeholder-neutral-500 dark:focus:ring-neutral-600 dark:focus:border-b-neutral-600"
              v-model="graphThreshold" min="0">
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-8">
        <div v-if="loadReportResource.data?.domain_scores" class="2xl:flex-auto 2xl:basis-1/2 space-y-4 lg:space-y-8">
          <h3 class="font-semibold">Domains covered through guided explorations:</h3>
          <div class="h-[50vh] min-h-[50vh] w-full">
            <PieChart name="Domains" :chartData="domainData" @urlGenerated="(url) => setChartUrl(2, url)" />
          </div>
          <div class="flex flex-col gap-1 items-center text-xs text-gray-700">
            <p v-if="emptyDomains.length">Domains not covered: {{ emptyDomains.join(', ') }}</p>
            <p v-if="loadReportResource.data?.activity_count">
              Showing data from {{ loadReportResource.data.activity_count }} observations.
            </p>
          </div>
        </div>
        <div v-if="loadReportResource.data?.type_scores" class="2xl:flex-auto 2xl:basis-1/2 space-y-4 lg:space-y-8">
          <h3 class="font-semibold">Activity types:</h3>
          <div class="h-[50vh] min-h-[50vh] w-full">
            <PieChart name="Activity Types" :chartData="typeData" @urlGenerated="(url) => setChartUrl(3, url)" />
          </div>
          <div class="flex flex-col gap-1 items-center text-xs text-gray-700">
            <p v-if="emptyTypes.length">Activity types not covered: {{ emptyTypes.join(', ') }}</p>
            <p v-if="loadReportResource.data?.activity_count">
              Showing data from {{ loadReportResource.data.activity_count }} observations.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="loadReportResource.data?.report === null"
      class="flex justify-center items-center grow text-gray-700">
      <span v-if="userDetails?.user_roles && userDetails.user_roles.includes('Parent')">
        Report for this student has not been published yet.
      </span>
      <span v-else>
        No report found with default prompt. Either change prompt or generate a new report.
      </span>
    </div>
    <div v-else-if="loadReportResource.data?.status === 'fail' 
      && loadReportResource.data?.message === 'You are not authorized to access this resource'"
      class="flex justify-center items-center grow text-gray-700">
      You are not authorized to access this resource
    </div>
  </div>
</template>

<script setup>
import { createResource } from 'frappe-ui';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import BarChartColour from '@/components/charts/BarChartColour.vue';
import { formatDateWithSuffix } from '../lib/utils';
import QuillEditorMinimal from '@/components/ui/input/QuillEditorMinimal.vue';
import PieChart from '@/components/charts/PieChart.vue';
import { marked } from 'marked';
import jsPDF from 'jspdf'
import ReportTypeDropdown from '@/components/observations/summary/individual/ReportTypeDropdown.vue';

const mdToHtml = (mdText) => {
  return marked(mdText || '')
}

const userDetails = inject('userDetails')

const route = useRoute()
// console.log({route: route.params.student})

const studentObj = computed(() => {
  if (!route.params.student) return {}
  if (!userDetails.value?.school_info) return {}
  const student = userDetails.value.school_info.students.find((obj) => obj.name === route.params.student)
  return student || {}
})


function getSchoolTerms(endDate) {
    let terms = [];
    let startYear = 2024;
    let currentYear = startYear;
    let currentMonth = 1;
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth() + 1;

    while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
        let termLabel = currentMonth === 1 ? `January-June ${currentYear}` : `August-December ${currentYear}`;
        terms.push(termLabel);
        
        if (currentMonth === 1) {
            currentMonth = 8;
        } else {
            currentMonth = 1;
            currentYear++;
        }
    }
    return terms;
}

function getAcademicYears(endDate) {
    let years = [];
    let startYear = 2023; // Adjusted to include 2023-2024
    let currentYear = startYear;
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth() + 1;

    while (currentYear < endYear || (currentYear === endYear && endMonth >= 6)) {
        years.push(`${currentYear}-${currentYear + 1}`);
        currentYear++;
    }
    return years;
}


// const reportTypes = ['Monthly', 'Term', 'Yearly']
const reportTypes = ['Term', 'Yearly']
const selectedReportType = ref('Term')

// const intervals = ref(getAcademicYears(new Date()))
const intervals = ref(getSchoolTerms(new Date()))
const selectedInterval = ref(intervals.value[intervals.value.length - 2])
console.log("intervals", intervals)

const changeReportType = (newType) => {
  if (selectedReportType.value !== 'Term' && newType === 'Term') {
    const terms = getSchoolTerms(new Date())
    intervals.value = terms;
    selectedInterval.value = terms[terms.length - 1]
  } else if (selectedReportType.value !== 'Yearly' && newType === 'Yearly') {
    const academicYears = getAcademicYears(new Date())
    intervals.value = academicYears;
    selectedInterval.value = academicYears[academicYears.length - 1]
  }
  selectedReportType.value = newType
}

const changeInterval = (newInterval) => {
  selectedInterval.value = newInterval;
  console.log("new interval", newInterval)
}

const isEditingComments = ref(false)
const isSavingComments = ref(false)
const comments = ref('')

const saveCommentsResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.individual_observation_reports.api.save_comment',
})

const saveEditedComments = async () => {
  console.log("Saving edit comments")
  isSavingComments.value = true;
  try {
    await saveCommentsResource.submit({
      report_id: loadReportResource.data.report.name,
      comment: comments.value,
    })

    if (saveCommentsResource.data.status === 'success') {
      console.log("Save successful")
      isEditingComments.value = false
      // loadReportResource.reload({ student: route.params.student })
    } else {
      console.error("Editing Report failed")
      console.error(saveCommentsResource.data.message)
    }
  } catch (error) {
    console.error("Edit request failed")
    console.error(error)
  }
  isSavingComments.value = false
}

function extractAcademicYear(intervalString) {
    const parts = intervalString.split(" ");
    if (parts.length !== 2) {
        throw new Error(`Invalid format: ${intervalString}`);
    }

    const interval = parts[0];
    const year = parseInt(parts[1], 10);

    if (interval === "August-December") {
        return `${year}-${year + 1}`; // Aug-Dec belongs to the starting academic year
    } else if (interval === "January-June") {
        return `${year - 1}-${year}`; // Jan-June belongs to the ending academic year
    }

    throw new Error(`Unknown interval: ${intervalString}`);
}

function extractIntervalIdentifier(intervalString) {
    return intervalString.split(" ")[0]; // Return only the first part before space
}

const loadReportResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_final_student_report',
  params: {
    student: route.params.student,
    report_type: selectedReportType.value,
    academic_year: selectedReportType.value === 'Yearly' ? selectedInterval.value : extractAcademicYear(selectedInterval.value),
    interval: selectedReportType.value !== 'Yearly' ? extractIntervalIdentifier(selectedInterval.value) : "",
  },
  auto: true
})

watch([selectedInterval, selectedReportType], ([newInterval, newReport]) => {
  loadReportResource.reload({
    student: route.params.student,
    report_type: newReport,
    academic_year: newReport === 'Yearly' ? newInterval : extractAcademicYear(newInterval),
    interval: newReport !== 'Yearly' ? extractIntervalIdentifier(newInterval) : "",
  })
})

if (loadReportResource.data?.status === 'success') {
  comments.value = loadReportResource.data?.report?.comments;
}
watch(() => loadReportResource.data, () => {
  if (loadReportResource.data.status === 'success') {
    comments.value = loadReportResource.data?.report?.comments;
  }
})

console.log(loadReportResource)

const regenResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.individual_observation_reports.api.regenerate_from_comment',
})

const isRegenerating = ref(false)

const regenReport = async () => {
  console.log("Regenerating Report")
  isRegenerating.value = true;
  try {
    await regenResource.submit({
      report_id: loadReportResource.data.report.name,
    })

    if (regenResource.data.status === 'success') {
      console.log("Save successful")
      loadReportResource.reload({ 
        student: route.params.student,
        report_type: selectedReportType.value,
        academic_year: selectedReportType.value === 'Yearly' ? selectedInterval.value : extractAcademicYear(selectedInterval.value),
        interval: selectedReportType.value !== 'Yearly' ? extractIntervalIdentifier(selectedInterval.value) : "",
      })
    } else {
      console.error("Editing Report failed")
      console.error(regenResource.data.message)
    }
  } catch (error) {
    console.error("Edit request failed")
    console.error(error)
  }
  isRegenerating.value = false
}

const editReportResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.edit_report',
})

const resetEditsResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.reset_edits',
})
// console.log(loadReportResource)

const isPublished = ref(loadReportResource.data?.report.is_published || false)

watch(() => loadReportResource.data, () => {
  if (loadReportResource.data?.status === 'success') {
    isPublished.value = !!loadReportResource.data.report?.is_published;
  }
})

const barChartDimNames = ['count', 'engagement score', 'competency']
const maxCount = ref(5)
const graphThreshold = ref(0)

const countScoreComp = computed(() => {
  if (!loadReportResource.data?.competency_scores) return [];
  return loadReportResource.data.competency_scores
    .filter((scoreObj) => scoreObj.count >= graphThreshold.value)
    .map((scoreObj) => { 
      if (scoreObj.count > maxCount.value) {
        maxCount.value = scoreObj.count;
      }
      return [scoreObj.count, scoreObj.average_engagement, scoreObj.competency]
    })
})

const barChartScoreText = ['Many Observations', 'Few Observations']

function roundUpToNearestFive(number) {
  return Math.ceil(number / 5) * 5;
}

const isEditing = ref(false)
const intermediateReport = ref('')
const editedReport = ref('')
const isSavingReport = ref(false)
const showSideBySide = ref(false)

const modifyReport = (newReport) => {
  intermediateReport.value = newReport
}

const saveEditedReport = async () => {
  console.log("Saving edit")
  isSavingReport.value = true
  try {
    await editReportResource.submit({
      report_id: loadReportResource.data.report.name,
      edited_report: intermediateReport.value,
    })

    if (editReportResource.data.status === 'success') {
      console.log("Save successful")
      editedReport.value = intermediateReport.value
      isEditing.value = false
      loadReportResource.reload({ 
        student: route.params.student,
        report_type: selectedReportType.value,
        academic_year: selectedReportType.value === 'Yearly' ? selectedInterval.value : extractAcademicYear(selectedInterval.value),
        interval: selectedReportType.value !== 'Yearly' ? extractIntervalIdentifier(selectedInterval.value) : "",
      })
    } else {
      console.error("Editing Report failed")
      console.error(editReportResource.data.message)
    }
  } catch (error) {
    console.error("Edit request failed")
    console.error(error)
  }
  isSavingReport.value = false
}

const resetToOriginal = async () => {
  isSavingReport.value = true
  try {
    await resetEditsResource.submit({
      report_id: loadReportResource.data.report.name,
    })

    if (resetEditsResource.data.status === 'success') {
      editedReport.value = ''
      isEditing.value = false
      loadReportResource.reload({ 
        student: route.params.student, 
        report_type: selectedReportType.value,
        academic_year: selectedReportType.value === 'Yearly' ? selectedInterval.value : extractAcademicYear(selectedInterval.value),
        interval: selectedReportType.value !== 'Yearly' ? extractIntervalIdentifier(selectedInterval.value) : "",
      })
    } else {
      console.error("Resetting Report failed")
      console.error(editReportResource.data.message)
    }
  } catch (error) {
    console.error("Reset request failed")
    console.error(error)
  }
  isSavingReport.value = false
}

const domainData = computed(() => {
  if (!loadReportResource.data?.domain_scores) {
    return []
  }
  return Object.keys(loadReportResource.data.domain_scores)
    .filter(domain => loadReportResource.data.domain_scores[domain])
    .map(domain => ({
      name: domain,
      value: loadReportResource.data.domain_scores[domain]
    }))
})

const emptyDomains = computed(() => {
  if (!loadReportResource.data?.domain_scores) {
    return []
  }
  return Object.keys(loadReportResource.data.domain_scores)
    .filter(domain => !loadReportResource.data.domain_scores[domain])
    .map(domain => (domain))
})
// console.log(domainData)

const typeData = computed(() => {
  if (!loadReportResource.data?.type_scores) {
    return []
  }
  return Object.keys(loadReportResource.data.type_scores)
    .filter(activity_type => loadReportResource.data.type_scores[activity_type])
    .map(activity_type => ({
      name: activity_type,
      value: loadReportResource.data.type_scores[activity_type]
    }))
})

const emptyTypes = computed(() => {
  if (!loadReportResource.data?.type_scores) {
    return []
  }
  return Object.keys(loadReportResource.data.type_scores)
    .filter(activity_type => !loadReportResource.data.type_scores[activity_type])
    .map(activity_type => (activity_type))
})

console.log(typeData)

const pageRef = ref(null)

const chartUrls = ref([null, null, null])

const setChartUrl = (num, url) => {
  chartUrls.value[num - 1] = url;
}

// Helper function to convert Blob to base64
const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result.split(',')[1]) // Remove the data URL prefix
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

// Function to convert font file to base64
const fetchFontAsBase64 = async (fontPath) => {
  try {
    const response = await fetch(fontPath, {
      method: 'GET',
      headers: {
        'X-Frappe-Csrf-Token': window.csrf_token
      },
    })
    if (!response.ok) {
      throw new Error(`Failed to fetch font: ${response.statusText}`)
    }
    const fontBlob = await response.blob()
    return await blobToBase64(fontBlob)
  } catch (error) {
    console.error('Error fetching or converting font:', error)
    throw error
  }
}

const printLinesToDoc = (doc, cursorY, lines, indent = 0, fontSize = 10) => {
  const pdfHeight = doc.internal.pageSize.getHeight()
  let localCursorY = cursorY;
  const lineSpace = 8

  lines.forEach(line => {
    const remainingHeight = pdfHeight - localCursorY
    if (remainingHeight < 10 + 50) {
      doc.addPage();
      localCursorY = 50;
    }
    doc.text(line, 30 + indent, localCursorY)
    localCursorY += (fontSize + lineSpace)
  })

  return localCursorY
}

const printLinesToDocXY = (doc, cursorX, cursorY, lines, indent = 0, fontSize = 10, styles, isBullet = false) => {
  // console.log("Tyring ot print lines to doc", {doc, cursorX, cursorY, lines, indent, fontSize, styles})
  const pdfHeight = doc.internal.pageSize.getHeight()
  const pdfWidth = doc.internal.pageSize.getWidth()
  let localCursorY = cursorY;
  let localCursorX = cursorX;
  const lineSpace = 8

  const linesLen = lines.length;
  lines.forEach((line, index) => {
    const textWidth = doc.getTextWidth(line);
    const remainingHeight = pdfHeight - localCursorY - 10
    if (remainingHeight < fontSize + 50) {
      doc.addPage();
      localCursorY = 50;
    }

    // const remainingSpace = 30 - indent - localCursorX;
    const remainingSpace = pdfWidth - 30 - localCursorX;
// pdfWidth - remainingSpace
    if (textWidth > (remainingSpace)) {
      localCursorY += 18;
      localCursorX = indent;
    }
    doc.setFontSize(fontSize);
    doc.setFont(...styles);
    try {
      // console.log("Printing line ", line)
// localCursorX + (localCursorX > indent ? 0 : indent)
      doc.text(line, localCursorX, localCursorY);
      // console.log("Print", {localCursorX, localCursorY})
    } catch (error) {
      console.log("Faield to print text", error)
    }
    // localCursorX += textWidth
    // console.log("Text width ", textWidth)
    // console.log("Indent: ", indent)
    // console.log("Space: ", remainingSpace)
    // console.log("Xposition: ", localCursorX)
    if (index < linesLen - 1) {
      localCursorX = indent
      localCursorY += (fontSize + lineSpace)
    } else {
      // localCursorX += (textWidth + indent);
      localCursorX += (textWidth);
    }
      // console.log("Print", {localCursorX, localCursorY})
  })

  // console.log("returning", [ localCursorX, localCursorY ])
  return [ localCursorX, localCursorY ]
}

function centerText(doc, text, cursorY) {
  const pdfWidth = doc.internal.pageSize.getWidth()
  const lines = doc.splitTextToSize(text, pdfWidth - (60))
  lines.forEach(line => {
    const textWidth = doc.getTextWidth(line);
    const x = (pdfWidth - 60 - textWidth) / 2;
    doc.text(line, 30 + x, cursorY);
    cursorY += 8
  })
  return cursorY
}

const processChildren = (doc, children, cursorY, indent = 0) => {
  const x = 30
  const pdfWidth = doc.internal.pageSize.getWidth()
  let lineHeight = 0;
  children.forEach((child) => {
    switch (child.nodeName) {
      case '#text':
        const textSegments = doc.splitTextToSize(child.textContent, pdfWidth - 2 * x - indent);
        textSegments.forEach((segment) => {
          doc.text(segment, x + indent, cursorY);
          cursorY += 10;
        });
        lineHeight = Math.max(lineHeight, textSegments.length * 10);
        break;
      case 'STRONG':
        doc.setFont('Poppins MD', 'normal');
        processChildren(doc, child.childNodes, cursorY, indent);
        doc.setFont('Poppins MD', 'light');
        break;
      case 'EM':
        doc.setFont('Poppins', 'light-italic');
        processChildren(doc, child.childNodes, cursorY, indent);
        doc.setFont('Poppins', 'light');
        break;
      case 'BR':
        cursorY += 10;
        break;
      default:
        processElement(doc, child, cursorY, indent);
        break;
    }
  });
  cursorY += lineHeight;
};

const getItemsToPrint = (element) => {
  const itemsToPrint = []
  const children = element.childNodes;

  for (const child of children) {
    switch (child.nodeName) {
      case '#text':
        itemsToPrint.push({ type: 'text', value: child.textContent })
        break;
      case 'EM':
        itemsToPrint.push({ type: 'EM', value: getItemsToPrint(child), parent: child.nodeName })
        break;
      case 'STRONG':
        itemsToPrint.push({ type: 'STRONG', value: getItemsToPrint(child), parent: child.nodeName })
        break;
      case 'BR':
        // itemsToPrint.push({ type: 'BR'})
        break;
      case 'SPAN':
        if (!child.childNodes.length) break;
        // console.log("Case span")
        const items = { type: 'SPAN', value: getItemsToPrint(child) }
        itemsToPrint.push(items)
        // console.log("items: ", items)
        break;
      case 'P':
        itemsToPrint.push({ type: 'text', value: child.textContent })
        break;
      default:
        break;
    }
  }
  return itemsToPrint;
}

const itemStyles = {
  'EM': ['Poppins', 'light-italic'],
  'STRONG': ['Poppins MD', 'normal'],
  'normal': ['Poppins', 'light'],
  'both': ['Poppins', 'medium-italic']
}


const getLines = (doc, xCursor, indent, text) => {
  const pdfWidth = doc.internal.pageSize.getWidth()
  const textWidth = doc.getTextWidth(text);
  const spaceLeft = pdfWidth - 30 - xCursor;

  const lines = []
  if (textWidth > spaceLeft) {
    const splitResult = doc.splitTextToSize(text, spaceLeft)
    lines.push(splitResult[0])
    if (splitResult.length > 2) {
      const rest = text.slice(splitResult[0].length).trimLeft()
      lines.push(...getLines(doc, indent, indent, rest))
    } else {
      lines.push(splitResult[1].trimLeft())
    }
  } else {
    lines.push(text)
  }
  return lines;
}

const printItemsToDoc = (doc, items, xCursor = 0, yCursor = 0, parentType = 'normal', isBullet = false) => {
  // console.log("Trying to print items", { doc, items, xCursor, yCursor, parentType})
  const pdfHeight = doc.internal.pageSize.getHeight()
  const pdfWidth = doc.internal.pageSize.getWidth()

  let localX = xCursor;
  let localY = yCursor;
  const itemLen = items.length;
  for (let i = 0; i < itemLen; i++) {
    const item = items[i];
    if (item.type === 'text') {
      const textWidth = doc.getTextWidth(item.value);
      const spaceLeft = pdfWidth - 30 - localX;
      if (textWidth > spaceLeft) {
        // const lines = doc.splitTextToSize(item.value, spaceLeft)
        const nextItem = i < itemLen - 1 ? true : false;
        const lines = getLines(doc, localX, isBullet ? 62 : 30, item.value)
        // console.log("LINES: ", lines)
        // [localX, localY] = [0, 0]
        // console.log({textWidth, spaceLeft, localX, pdfWidth})
        const newIndex = printLinesToDocXY(doc, localX, localY, lines, isBullet ? 62 : 30, 10, itemStyles[parentType], isBullet)
        localX = newIndex[0]
        localY = newIndex[1]
        // console.log("Before loc", newIndex)
        // console.log("got loc", [localX, localY])
      } else {
        const remainingHeight = pdfHeight - localY
        if (remainingHeight < 10 + 50) {
          doc.addPage();
          localY = 50;
        }

        if (localX === 30 && isBullet) {
          const pattern = /^\d+\.\s{2}/;
          if (item.value.startsWith(`•  `) || pattern.test(item.value))
            localX = 50
          else
            localX = 62
        }
        doc.setFontSize(10);
        doc.setFont(...itemStyles[parentType]);
        doc.text(item.value, localX, localY);
        // if (item.value.startsWith('Overall Engagement') || item.value.startsWith('activities')) {
        //   console.log("------------------")
        //   console.log("Printing first line: ", {localX, textWidth})
        //   console.log("LIne: ", item.value)
        //   console.log("Width: ", textWidth)
        // }
        localX += textWidth;
      }
    } else if (item.type === 'EM') {
      // console.log("EMEMEME", item.value)
      // console.log({ localX, localY})
      const newParentType = (parentType === 'EM' || parentType === 'STRONG') ? 'both' : 'EM';
      [localX, localY] = printItemsToDoc(doc, item.value, localX, localY, newParentType, isBullet);
    } else if (item.type === 'STRONG') {
      const newParentType = (parentType === 'EM' || parentType === 'STRONG') ? 'both' : 'STRONG';
      [localX, localY] = printItemsToDoc(doc, item.value, localX, localY, newParentType, isBullet);
    } else if (item.type === 'BR') {
      localX = 30
      localY += 18
    } else if (item.type === 'span') {
      [localX, localY] = printItemsToDoc(doc, item.value, localX, localY, 'normal');
    }
  }

  return [localX, localY];
}

// type can be 'ul' or 'ol'
const printListItems = (doc, liElement, index, type, cursorY, indent = 50) => {
  const x = 30;
  const pdfWidth = doc.internal.pageSize.getWidth()
  if (type === 'ul') {
    // const children = liElement.childNodes;
    // console.log("list children: ", children)
    const itemsToPrint = [
      { type: 'text', value : `•  `, bullet: true }, 
      ...getItemsToPrint(liElement)
    ];
    // console.log('item print', itemsToPrint)
    // const liText = doc.splitTextToSize(`•  ${liElement.innerText}`, pdfWidth - (2 * x))
    // cursorY = printLinesToDoc(doc, cursorY, liText, indent);
    cursorY = printItemsToDoc(doc, itemsToPrint, indent, cursorY, 'normal', true)[1]
  } else if (type === 'ol') {
    const itemsToPrint = [{ type: 'text', value : `${index + 1}.  ` }, ...getItemsToPrint(liElement)];
    // console.log("items to print: ", itemsToPrint)
    // const liText = doc.splitTextToSize(`${index + 1}.  ${liElement.innerText}`, pdfWidth - (2 * x))
    // cursorY = printLinesToDoc(doc, cursorY, liText, indent);
    cursorY = printItemsToDoc(doc, itemsToPrint, indent, cursorY, 'normal', true)[1]
  }
  cursorY += (10 + 8)
  return cursorY
}

  // Function to convert HTML content to PDF
  const addHtmlToPdf = (doc, html, x, y) => {
    const processElement = (element, indent = 0) => {
      switch (element.tagName) {
        case 'H1':
          doc.setFontSize(18)
          doc.setFont('Poppins', 'semibold')
          const h1Text = doc.splitTextToSize(element.innerText, pdfWidth - (2 * x))
          cursorY = printLinesToDoc(doc, cursorY, h1Text, 0, 14);
          cursorY += 8
          doc.setFontSize(12)
          doc.setFont('Poppins', 'light')
          // doc.text(h1Text, x + indent, cursorY)
          // cursorY += ((10 + lineSpace) * h1Text.length)
          break
        case 'H2':
          doc.setFontSize(14)
          doc.setFont('Poppins MD', 'normal')
          const h2Text = doc.splitTextToSize(element.innerText, pdfWidth - (2 * x))
          cursorY = printLinesToDoc(doc, cursorY, h2Text, 0, 10);
          cursorY += 8
          doc.setFontSize(12)
          doc.setFont('Poppins', 'light')
          // doc.text(h1Text, x + indent, cursorY)
          // cursorY += ((10 + lineSpace) * h1Text.length)
          break
        case 'P':
          // const children = element.childNodes
          const itemsToPrint = getItemsToPrint(element);
          cursorY = printItemsToDoc(doc, itemsToPrint, 30, cursorY)[1]

          // let lineText = ''
          // for (const child of children) {
          //   if (child.nodeName === '#text') {
          //     lineText += child.textContent
          //   } else if (child.nodeName === 'BR') {
          //     const pText = doc.splitTextToSize(lineText, pdfWidth - (2 * x))
          //     cursorY = printLinesToDoc(doc, cursorY, pText);
          //     lineText = ''
          //   }
          // }
          // const p2Text = doc.splitTextToSize(lineText, pdfWidth - (2 * x))
          // cursorY = printLinesToDoc(doc, cursorY, p2Text);
          cursorY += 26

          // processChildren(doc, children, cursorY)
          // let lineText = ''
          // for (const child of children) {
          //   if (child.nodeName === '#text') {
          //     lineText += child.textContent
          //   } else if (child.nodeName === 'STRONG') {
          //     doc.setFont('Poppins MD', 'normal')
          //     lineText += child.innerText
          //     doc.setFont('Poppins MD', 'light')
          //   } else if (child.nodeName === 'EM') {
          //     doc.setFont('Poppins', 'light-italic')
          //     lineText += child.innerText
          //     doc.setFont('Poppins', 'light')
          //   } else if (child.nodeName === 'BR') {
          //     const pText = doc.splitTextToSize(lineText, pdfWidth - (2 * x))
          //     // doc.text(pText, x + indent, cursorY)
          //     cursorY = printLinesToDoc(doc, cursorY, pText);
          //     lineText = ''
          //     // cursorY += 10 + lineSpace
          //     // cursorY += ((10 + lineSpace) * pText.length)
          //   }
          // }
          // const p2Text = doc.splitTextToSize(lineText, pdfWidth - (2 * x))
          // cursorY = printLinesToDoc(doc, cursorY, p2Text);
          break
        case 'OL':
          // console.log(element)
          const olItems = element.children
          for (let i = 0; i < olItems.length; i++) {
            const item = olItems[i]
            // console.log(item)
            const listType = item.getAttribute('data-list');
            // console.log(listType)
            if (listType === 'ordered')
              cursorY = printListItems(doc, item, i, 'ol', cursorY, x + 20)
            else if (listType === 'bullet')
              cursorY = printListItems(doc, item, i, 'ul', cursorY, x + 20)
            else if (!listType && item.nodeName === 'LI') {
              cursorY =  printListItems(doc, item, i, 'ol', cursorY, x + 20)
            }
          }
          cursorY += 12
          break
        case 'UL':
          const ulItems = element.children
          console.log({ulItems})
          for (let i = 0; i < ulItems.length; i++) {
            const item = ulItems[i]
            if (item.nodeName === 'LI')
              cursorY = printListItems(doc, item, i, 'ul', cursorY, x + 20)
          }
          break;
        default:
          break
      }
    }

    let cursorY = y
    let lineSpace = 8

    const pdfHeight = doc.internal.pageSize.getHeight()
    const pdfWidth = doc.internal.pageSize.getWidth()

    // Check if the content is plain text or HTML
    let isHtml = /<\/?[a-z][\s\S]*>/i.test(html)
    if (!isHtml) {
      html = mdToHtml(html);
    }
    isHtml = /<\/?[a-z][\s\S]*>/i.test(html)
    console.log(html)
    if (!isHtml) {
      // Handle plain text
      doc.setFontSize(10)
      doc.setFont('Poppins', 'light')
      const lines = doc.splitTextToSize(html, doc.internal.pageSize.width - x * 2)
      lines.forEach(line => {
        const remainingHeight = pdfHeight - cursorY
        if (remainingHeight < 10 + 50) {
          doc.addPage();
          cursorY = 50;
        }
        doc.text(line, x, cursorY)
        cursorY += 10 + lineSpace
      })
    } else {
      // Handle HTML content
      const parser = new DOMParser()
      const parsedHtml = parser.parseFromString(html, 'text/html')
      const elements = parsedHtml.body.children

      doc.setFontSize(10)
      doc.setFont('Poppins', 'light')

      for (const element of elements) {
        processElement(element)
      }
    }

  return cursorY;
}

const calculateImageSize = (imgProps, maxWidth) => {
  let width = imgProps.width;
  let height = imgProps.height;

  if (imgProps.width > maxWidth) {
    const aspectRatio = width / height;
    width = maxWidth;
    height = maxWidth / aspectRatio;
  }

  return [ width, height ]
}

function addImageWithPageBreak(doc, url, cursorY, textArray) {
    const imgProps = doc.getImageProperties(url);
    
    const lineSpace = 8;
    let textHeight = 0;
    textHeight += (12 + lineSpace) + (lineSpace * textArray.length)

    const pdfWidth = doc.internal.pageSize.getWidth()

    const [ imageWidth, imageHeight ] = calculateImageSize(imgProps, pdfWidth - 60)

    const pdfHeight = doc.internal.pageSize.getHeight()
    const remainingHeight = pdfHeight - cursorY - 50

    // if ((cursorY + imageHeight + textHeight) > remainingHeight) {
    if ((imageHeight + textHeight) > remainingHeight) {
      let newCursorY = 50;
      doc.addPage();

      doc.setFontSize(12)
      doc.setTextColor(17, 24, 39)
      doc.setFont('Poppins MD', 'normal')
      doc.text(textArray[0], 30, newCursorY)
      newCursorY += lineSpace
        // Calculate remaining space on current page
        // const remainingSpace = pdfHeight - cursorY;

        // Add part of the image that fits
        doc.addImage(url, 'PNG', 30, newCursorY, imageWidth, imageHeight);

        newCursorY += (imageHeight + 12 + lineSpace)

        textArray.slice(1).forEach(text => {
          doc.setFontSize(8)
          doc.setTextColor(75, 85, 99)
          doc.setFont('Poppins', 'light')
          // doc.text(text, 30, newCursorY)
          newCursorY = centerText(doc, text, newCursorY)
        })

        return newCursorY + 12
    } else {
      doc.setFontSize(12)
      doc.setTextColor(17, 24, 39)
      doc.setFont('Poppins MD', 'normal')
      doc.text(textArray[0], 30, cursorY)
      cursorY += lineSpace
      textHeight += 10 + lineSpace

      // If there's enough space, add the image normally
      doc.addImage(url, 'PNG', 30, cursorY, imageWidth, imageHeight);
      cursorY += (imageHeight + 12 + lineSpace)

      textArray.slice(1).forEach(text => {
        doc.setFontSize(8)
        doc.setTextColor(75, 85, 99)
        doc.setFont('Poppins', 'light')
        // doc.text(text, 30, cursorY)
        // cursorY += lineSpace
        cursorY = centerText(doc, text, cursorY)
      })
      
      return cursorY + 12
    }
}

const isPrinting = ref(false)

const printPage = async () => {
// console.log(pageRef.value.innerHTML)
  isPrinting.value = true
  // window.print()
  await nextTick();
  const doc = new jsPDF('p', 'pt', 'a4')

  try {
    const poppinsMDbase64 = await fetchFontAsBase64(`/assets/curriculum_planner/front-portal/fonts/poppins/poppins-v21-latin-500.ttf`)
    // const poppinsMDbase64 = await fetchFontAsBase64(`/fonts/poppins/poppins-v21-latin-500.ttf`)
    doc.addFileToVFS('Poppins-Medium.ttf', poppinsMDbase64)
    doc.addFont('Poppins-Medium.ttf', 'Poppins MD', 'normal');
    const poppinsSMbase64 = await fetchFontAsBase64(`/assets/curriculum_planner/front-portal/fonts/poppins/poppins-v21-latin-300.ttf`)
    // const poppinsSMbase64 = await fetchFontAsBase64(`/fonts/poppins/poppins-v21-latin-300.ttf`)
    doc.addFileToVFS('Poppins-Light.ttf', poppinsSMbase64)
    doc.addFont('Poppins-Light.ttf', 'Poppins', 'light');
    const poppinsNRbase64 = await fetchFontAsBase64(`/assets/curriculum_planner/front-portal/fonts/poppins/poppins-v21-latin-regular.ttf`)
    doc.addFileToVFS('Poppins-Normal.ttf', poppinsNRbase64)
    doc.addFont('Poppins-Normal.ttf', 'Poppins', 'normal');
    const poppinsBoldbase64 = await fetchFontAsBase64(`/assets/curriculum_planner/front-portal/fonts/poppins/poppins-v21-latin-700.ttf`)
    doc.addFileToVFS('Poppins-Bold.ttf', poppinsBoldbase64)
    doc.addFont('Poppins-Bold.ttf', 'Poppins', 'bold');
    const poppinsSemibase64 = await fetchFontAsBase64(`/assets/curriculum_planner/front-portal/fonts/poppins/poppins-v21-latin-600.ttf`)
    doc.addFileToVFS('Poppins-Semi.ttf', poppinsSemibase64)
    doc.addFont('Poppins-Semi.ttf', 'Poppins', 'semibold');
    const poppinsLIbase64 = await fetchFontAsBase64(`/assets/curriculum_planner/front-portal/fonts/poppins/poppins-v21-latin-300italic.ttf`)
    doc.addFileToVFS('Poppins-LI.ttf', poppinsLIbase64)
    doc.addFont('Poppins-LI.ttf', 'Poppins', 'light-italic');
    const poppinsMediItalicbase64 = await fetchFontAsBase64(`/assets/curriculum_planner/front-portal/fonts/poppins/poppins-v21-latin-500italic.ttf`)
    doc.addFileToVFS('Poppins-MI.ttf', poppinsMediItalicbase64)
    doc.addFont('Poppins-MI.ttf', 'Poppins', 'medium-italic');
  } catch (error) {
    console.error('Error converting font to base64:', error)
  }

  // doc.html(pageRef.value, { x:10, y:10, width: 180, windowWidth: 800 })

  // doc.setFontSize(24)
  // doc.setTextColor(255, 0, 0)
  // doc.text('My Webpage', 10, 20)

  // doc.setFontSize(12)
  // doc.setTextColor(0, 0, 0)
  // const paragraph = 'This is the content of the webpage. It will be converted to a PDF with custom CSS and images.'
  // doc.text(paragraph, 10, 30)
  // const x = 20
  // const y = 20
  // const width = 160
  // const height = 60
  // const radius = 10
  // const shadowOffset = 1 // Adjusted to create a subtler shadow effect
  // const shadowBlur = 2 // Adjusted to simulate blur effect

  // drawRoundedRectWithShadow(doc, x, y, width, height, radius, shadowOffset, shadowBlur)
  let cursorY = 50
  const lineSpace = 8

  doc.setFontSize(24)
  doc.setFont('Poppins MD', 'normal')
  const nameToUse = studentObj.value.official_name ? studentObj.value.official_name : studentObj.value.student_name;
  const title = `${nameToUse}'s Observation Report`;
  doc.text(title, 30, cursorY);
  cursorY += 12 + lineSpace

  doc.setFontSize(12)
  doc.setTextColor(75, 85, 99)
  doc.text(`${formatDateWithSuffix(loadReportResource.data.report.creation)}, ${new Date(loadReportResource.data.report.creation).getFullYear()}`, 30, cursorY)
  cursorY += 24 + lineSpace

  const loadOriginal = !!loadReportResource.data?.report?.is_original_preferred || !loadReportResource.data?.report?.edited_versions.length
  // console.log(loadReportResource.data?.report?.edited_versions[0].report)
  cursorY = loadOriginal ? 
    addHtmlToPdf(doc, loadReportResource.data?.report?.report, 30, cursorY)
    : addHtmlToPdf(doc, loadReportResource.data?.report?.edited_versions[loadReportResource.data?.report?.edited_versions?.length - 1].report, 30, cursorY)

  cursorY += 24

  // const imgProps = doc.getImageProperties(chartUrls.value[0])
  // const pdfWidth = doc.internal.pageSize.getWidth()
  // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

  // doc.addImage(chartUrls.value[0], 'PNG', 0, cursorY, pdfWidth, pdfHeight)
  const image1TextArray = [];
  image1TextArray.push(`Competencies:`)
  image1TextArray.push(`Showing data from ${loadReportResource.data.activity_count} observations.`)
  cursorY = addImageWithPageBreak(doc, chartUrls.value[0], cursorY, image1TextArray)

  const image2TextArray = [];
  image2TextArray.push(`Domains covered through guided explorations:`)
  if (emptyDomains.value.length)
    image2TextArray.push(`Domains not covered: ${emptyDomains.value.join(', ')}`)
  image2TextArray.push(`Showing data from ${loadReportResource.data.activity_count} observations.`)
  cursorY = addImageWithPageBreak(doc, chartUrls.value[1], cursorY, image2TextArray)

  const image3TextArray = [];
  image3TextArray.push(`Activity types:`)
  if (emptyTypes.value.length)
    image3TextArray.push(`Activity types not covered: ${emptyTypes.value.join(', ')}`)
  image3TextArray.push(`Showing data from ${loadReportResource.data.activity_count} observations.`)
  cursorY = addImageWithPageBreak(doc, chartUrls.value[2], cursorY, image3TextArray)

  // const img3Props = doc.getImageProperties(chartUrls.value[0])

  // doc.addImage(chartUrls.value[2], 'PNG', 0, 400, pdfWidth, pdfHeight)

  doc.save(`${studentObj.value.student_name}_Observation_Report_2024.pdf`)
  isPrinting.value = false
}

const publishReportResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.publish_or_unpublish_reports',
})

const publishReport = async () => {
  try {
    const reportArray =  [loadReportResource.data.report.name]
    await publishReportResource.submit({
      report_ids: reportArray,
      publish_status: true,
    })
    if (publishReportResource.data.status === 'success') {
      isPublished.value = true;
      console.log("Report published successfully")
    } else {
      console.log("Report was not published")
    }
  } catch (error) {
    console.error("Failed to make publish request")
    console.error(error)
  }
}

const unPublishReport = async () => {
  try {
    const reportArray =  [loadReportResource.data.report.name]
    await publishReportResource.submit({
      report_ids: reportArray,
      publish_status: false,
    })
    if (publishReportResource.data.status === 'success') {
      isPublished.value = false;
      console.log("Report unpublished successfully")
    } else {
      console.log("Report was not unpublished")
    }
  } catch (error) {
    console.error("Failed to make unpublish request")
    console.error(error)
  }
}

const notesInputRef = ref(null);

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  notesInputRef.value.style.height = 'auto'
  let calculatedHeight = notesInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  notesInputRef.value.style.height = `${calculatedHeight}px`
}
</script>

<style>
.richDisplay h1, h2, h3 {
  font-weight: 600;
}
</style>