<template>
  <div v-if="props.observation" class="p-4 space-y-2">
    <h3 v-if="!isSavePreview && props.observation.educator" 
      class="font-bold text-xl ">
      {{ findValueByName(userDetails?.school_info.educators, props.observation.educator, 'educator_name') }}
    </h3>
    <h3 v-if="isSavePreview && props.observation.educator_name" 
      class="font-bold text-xl ">
      {{ props.observation.educator_name }}
    </h3>
    <!-- <div v-if="!isSavePreview && props.observation.educator_name" class="space-y-2">
      <h4 class="font-semibold">
        Educator:
      </h4>
      <p class="text-gray-500">
        {{ findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name') }}
      </p>
    </div>
    <div v-if="isSavePreview && props.observation.educator_id" class="space-y-2">
      <h4 class="font-semibold">
        Educator:
      </h4>
      <p class="text-gray-500">
        {{ props.observation.educator_name }}
      </p>
    </div> -->
    <div v-if="props.observation.about" class="space-y-2">
      <h4 class="font-semibold">
        <span v-if="props.observation.about !== 'Week'">Who </span>
        <span v-if="props.observation.about === 'Week'">What </span>
        is this observation about:
      </h4>
      <p class="text-gray-500">
        {{ props.observation.about }}
      </p>
      <div v-if="!isSavePreview && props.observation.about === 'Students'" class="flex flex-wrap gap-2">
        <div v-for="student in props.observation.students"   
          class="bg-[#FDD835] px-2 rounded">
          {{ student.student_name }}
        </div>
      </div>
      <div v-if="isSavePreview && props.observation.about === 'Students'" class="flex flex-wrap gap-2">
        <div v-for="student in props.observation.students"   
          class="bg-[#FDD835] px-2 rounded">
          {{ findValueByName(userDetails?.school_info.students, student, 'student_name') }}
        </div>
      </div>
      <div v-if="props.observation.about === 'Cohort'" class="flex flex-wrap gap-2">
        <div class="bg-[#FDD835] px-2 rounded">
          {{ findValueByName(userDetails?.school_info.cohorts, props.observation.cohort, 'cohort_name') }}
        </div>
      </div>
    </div>
    <div v-if="props.observation.date" class="space-y-2">
      <h4 class="font-semibold">
        Date:
      </h4>
      <p class="text-gray-500">
        {{ observation.date }}
      </p>
    </div>
    <div v-if="props.observation.feedback" class="space-y-2">
      <h4 class="font-semibold">
        Observation:
      </h4>
      <div class="text-gray-500">
        {{ props.observation.feedback }}
      </div>
    </div>
    <div v-if="props.observation.feedback_text" class="space-y-2">
      <h4 class="font-semibold">
        Observation:
      </h4>
      <div class="text-gray-500">
        {{ props.observation.feedback_text }}
      </div>
    </div>
    <!-- <div v-if="!isSavePreview && props.observation.competencies" class="space-y-2">
      <h4 class="font-semibold">
        Competencies anecdote is linked to:
      </h4>
      <div class="flex flex-wrap gap-2">
        <div v-for="competency in props.observation.competencies"   
          class="bg-[#FDD835] px-2 rounded">
          {{ competency.competency_name }}
        </div>
      </div>
    </div> -->
    <div v-if="props.observation?.images?.length">
      <h4 class="font-semibold">
        Images:
      </h4>
      <div class="flex gap-2">
        <template v-for="(image, index) in props.observation.images">
          <div
            class="p-2 rounded hover:opacity-50 hover:bg-gray-200 cursor-pointer"
            @click="() => selectImage(image.presigned_image_link)">
            <img
              :key="image.name"
              :src="image.presigned_thumbnail_link"
              :alt="`Observation Image ${index}`"
              :class="cn(`size-28 md:size-28 rounded`)" />
          </div>
        </template>
        <!-- <div v-for="image in props.observation.images">{{ image.presigned_image_link }}</div> -->
      </div>
      <div>
        <!-- <p>Selected image:</p> -->
        <transition name="fade">
          <img v-if="selectedImageUrl" alt="Currently selected image."
            :src="selectedImageUrl" />
        </transition>
      </div>
    </div>
  </div>
  <div v-else class="h-[30vh] flex flex-col justify-center items-center gap-4">
    <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent 
      text-[#fdd835] rounded-full" role="status" aria-label="loading">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-gray-600">
      Loading observation. Please wait...
    </p>
  </div>
</template>

<script setup>
import { findValueByName } from '@/lib/utils'
import { inject, ref, watch } from 'vue';
import { cn } from '@/components/ui/utils.js';

const props = defineProps(['observation', 'isSavePreview'])

const userDetails = inject('userDetails')

const selectedImageUrl = ref('')

// should probably change to preline modal open method
watch(() => props.observation, (newObservation) => {
  selectedImageUrl.value = '';
})

const selectImage = (imageUrl) => {
  if (selectedImageUrl.value === imageUrl) {
    selectedImageUrl.value = '';
  } else {
    selectedImageUrl.value = imageUrl;
  }
}

watch(() => props.observation, () => {
  selectedImageUrl.value = ''
})
</script>

<style>
/* Transition styles for fade effect */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
